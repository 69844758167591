<template>
	<div class="row offers mt-2">
		<b-col lg="12">
			<div class="card">
				<b-row align-h="center">
					<b-col lg="6">
						<div class="p-2">
							<div class="mt-2 text-center">Search Ios Tier</div>
							<b-input-group class="mt-3">
								<b-form-input
									v-model="query_search"
									type="search"
									placeholder="Type to search"
									@change="setQuerySearch"
									trim
								></b-form-input>
								<b-input-group-append>
									<b-button type="submit" variant="primary" @click="asyncFind">Search</b-button>
									<b-button type="button" variant="dark" class="ml-2" @click="asyncClearFind">Clear</b-button>
									<b-spinner v-show="isLoadingSearch" variant="dark" class="ml-2" label="Loading .."></b-spinner>
								</b-input-group-append>
							</b-input-group>
						</div>
					</b-col>
				</b-row>
				<div class="card-body">
					<div class="row justify-content-end pb-1">
						<span class="pr-2">
							<router-link class="btn btn-sm btn-dark" :to="{name: IosTierCreatePath.name}">
                <em class="fa fa-plus"></em>
              </router-link>
						</span>
						<span>
							<button class="btn btn-sm btn-dark" @click="actionGetIosTiers">
								<em class="fa fa-refresh"></em>
							</button>
						</span>
						<label for="" class="col-sm-auto">Count </label>
						<strong class="col-sm-auto">{{ totalRows }}</strong>
					</div>
					<b-table
						sticky-header="500px"
						:items="items"
						:fields="fields"
						:busy="isLoading"
						:isLoading="isLoading"
						show-empty
						responsive="xl"
						class="mt-2"
						hover
					>
						<template v-slot:table-busy>
							<div class="text-center text-dark my-2">
								<b-spinner
									class="align-middle"
									variant="dark"
									type="grow"
									label="Loading .."
								>
								</b-spinner>
								Loading ..
							</div>
						</template>
						<template #cell(No)="row">
							{{ (currentPage - 1) * perPage + (row.index + 1) }}
						</template>
						<template #cell(tier_code)="row">
							{{ row.item.tier_code }}
						</template>
						<template #cell(price_idr)="row">
							{{ formatPrice(row.item.price_idr,"IDR") }}
						</template>
						<template #cell(tier_price)="row">
							{{ formatPrice(row.item.tier_price,"USD") }}
						</template>
						<template #cell(created)="row">
							{{ formatDate(row.item.created) }}
						</template>
						<template #cell(modified)="row">
							{{ formatDate(row.item.modified) }}
						</template>
						<template #cell(is_active)="row">
							<div>
								<span v-if="row.item.is_active" class="badge badge-success">
									Active
								</span>
								<span v-else class="badge badge-danger"> Inactive </span>
							</div>
						</template>
						<template #cell(action_edit)="row">
							<div class="text-right">
								<router-link class="btn btn-sm btn-default" :to="{name: IosTierEditPath.name, params: { id: row.item.id }}">
									<em class="fa fa-edit"></em>
								</router-link>
							</div>
						</template>
						<template #cell(action_show)="row">
							<div class="text-right">
								<router-link class="btn btn-sm btn-default" :to="{name: InvalidTiercodeOfferPath.name, params: { tiercode: row.item.tier_code, platform: platform_id }}">
									<em class="fa fa-eye"></em>
								</router-link>
							</div>
						</template>
					</b-table>
				</div>
				<b-row v-if="(totalRows/perPage) > 1" class="justify-content-start ml-1 mt-2 pt-4">
					<b-col lg="3">
						<b-pagination
							v-model="currentPage"
							:total-rows="totalRows"
							:per-page="perPage"
							aria-controls="my-table"
						/>
					</b-col>
				</b-row>
			</div>
		</b-col>
	</div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import { IosTierCreatePath, IosTierEditPath, InvalidTiercodeOfferPath } from "../../router/marketing";
import constant from "../../store/constant";
export default {
	name: "manage-ios-tier",
	data() {
		return {
			IosTierCreatePath,
			IosTierEditPath,
			InvalidTiercodeOfferPath,
			currentPage: 1,
			perPage: 20,
			platform_id: constant.PLATFORM.IOS,
			query_search: '',
			isLoadingSearch: false,
			fields: [
				{ key: "No", label: "No" },
        { key:'tier_code', label: 'Tier Code'},
        {
          key:'price_idr',
          label: 'Price IDR',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key:'tier_price',
          label: 'Tier Price',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
				{
					key: "created",
					label: "Created",
				},
				{
					key: "modified",
					label: "Modified",
				},
				{ key: "is_active", label: "Status" },
				{ key: "action_edit", label: "Edit" },
				{ key: "action_show", label: "Invalid Price" },
			],
		};
	},
	computed: {
		...mapState({
			isLoading: (state) => state.tiercodeIOS.isLoading,
			items: (state) => state.tiercodeIOS.items,
			isError: (state) => state.tiercodeIOS.isError,
			totalRows: (state) => state.tiercodeIOS.totalRows,
		}),
	},
	mounted() {
		this.actionGetIosTiers();
	},
	watch: {
		currentPage: function() {
			this.actionGetIosTiers();
		},
	},
	methods: {
		...mapActions("tiercodeIOS", ["fetchIosTier", "searchIosTier"]),
		actionGetIosTiers() {
			if (this.query_search == "") {
				let payload = {
					page: this.currentPage,
					limit: this.perPage,
				};
				this.fetchIosTier(payload);
			} else {
				this.asyncFind();
			}
		},
		setQuerySearch(query) {
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.query_search = query;
			}, 1000);
		},
    formatPrice(value, currency) {
			if (value) {
				let formatter = null;
				if (currency == "USD") {
					formatter = new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: currency,
					});
				} else {
					formatter = new Intl.NumberFormat("en-ID", {
						style: "currency",
						currency: currency,
					});
				}

				return formatter.format(value);
			}

			return "0";
		},
		formatDate(tgl) {
			if (tgl) {
				return moment(tgl).format("DD MMM YY hh:mm");
			}
			return "-";
		},
		asyncFind() {
			this.isLoadingSearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				const query = this.query_search;
				if (!query) {
					this.actionGetIosTiers();
					this.isLoadingSearch = false;
					return;
				}
				this.searchIosTier({
					q: query,
					page: this.currentPage,
					limit: this.perPage,
				})
					.then((response) => {
						this.items = response.data.data.rows;
						this.isLoadingSearch = false;
					})
					.catch(() => {
						this.isLoadingSearch = false;
					});
			}, 1000);
		},
		asyncClearFind() {
			this.query_search = "";
			this.actionGetIosTiers();
			this.isLoadingSearch = false;
		},
	},
};
</script>
